import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";

export default function PortfolioMetrics({ data, drawdown_win, drawdown_wdo, switchInterval = 0 }) {
    const [helpVisible, setHelpVisible] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [displayedText, setDisplayedText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [drawdownIndex, setDrawdownIndex] = useState(0); // Índice para alternar entre os valores de drawdown
    const typingSpeed = 3; // Velocidade de digitação (ms por caractere)
    const scrollRef = useRef(null); // Ref para o div que conterá o texto

    function formatBRL(number) {
        // Se o número for undefined ou null, retorna "R$ 0,00"
        if (number === undefined || number === null) {
            return "R$ 0,00";
        }
    
        return number.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
    }
    
    const formatTextForDisplay = (text) => {
        if (!text) return '';
    
        // Negrito *EXCELENTE*
        text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
    
        // Itálico _italico_
        text = text.replace(/_(.*?)_/g, '<em>$1</em>');
    
        // Sublinhado ~sublinhado~
        text = text.replace(/~(.*?)~/g, '<u>$1</u>');
    
        // Tratamento para múltiplos tipos de quebra de linha (\n ou \r\n ou \r)
        text = text.replace(/(\r\n|\n|\r)/g, '<br/>');
    
        return text;
    };
    
    const toggleHelp = (section) => {
        setHelpVisible(helpVisible === section ? null : section);
    };

    const openModal = (section) => {
        const detailedText = formatTextForDisplay(data[`${section}_detalhe_observacao`]);
        setModalContent(detailedText);
        setDisplayedText(''); // Reseta o texto mostrado
        setCurrentIndex(0); // Reseta o índice atual para o efeito de digitação
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (isModalOpen && currentIndex < modalContent.length) {
            const timeout = setTimeout(() => {
                setDisplayedText((prev) => prev + modalContent[currentIndex]);
                setCurrentIndex((prev) => prev + 1);
                if (scrollRef.current) {
                    scrollRef.current.scrollTop = scrollRef.current.scrollHeight; // Rola para o final
                }
            }, typingSpeed);

            return () => clearTimeout(timeout);
        }
    }, [isModalOpen, currentIndex, modalContent]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        if (isModalOpen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent('');
        setDisplayedText('');
        setCurrentIndex(0);
    };

    // Alternância entre os valores de Drawdown
    useEffect(() => {
        if (switchInterval > 0) {
            const interval = setInterval(() => {
                setDrawdownIndex((prevIndex) => (prevIndex + 1) % 4); // Alterna entre 3 estados
            }, switchInterval * 1000); // Converte para milissegundos

            return () => clearInterval(interval);
        }
    }, [switchInterval]);

    // Função para alternar entre diferentes valores de Drawdown
    const renderDrawdown = () => {
        // Verifica se o array drawdown_list existe e contém pelo menos um valor
        if (!data.drawdown_list || data.drawdown_list.length === 0) {
            return <span>Dados de drawdown indisponíveis</span>; // Retorna uma mensagem apropriada caso não haja dados
        }
    
        // Verifica se o switchInterval é zero e retorna apenas o valor formatado do drawdown máximo
        if (switchInterval === 0) {
            const drawdown = data.drawdown_list[0]; // Utiliza o primeiro drawdown
    
            // Formatação do valor do drawdown
            const drawdownValue = formatBRL(drawdown.drawdown);
    
            return (
                <span>{drawdownValue}</span>
            );
        }
    
        // Adiciona o novo estado para exibir o "Máximo" antes de alternar entre os outros valores
        if (drawdownIndex === 0) {
            return (
                <span>
                    <strong>Máximo:</strong> {formatBRL(data.drawdown_maximo_em_reais)} 
                    <span style={{ color: 'red' }}> ({formatBRL(data.drawdown_maximo_em_reais - drawdown_win - drawdown_wdo)})</span>
                </span>
            );
        }
    
        // Primeiro drawdown (1º maior)
        if (drawdownIndex === 1) {
            const drawdown = data.drawdown_list[0]; // Primeiro drawdown
            const drawdownValue = formatBRL(drawdown.drawdown);
            const drawdownDuration = `${drawdown.duration} dias úteis`;
    
            return (
                <span>
                    <strong>1º maior:</strong> {drawdownValue} ({drawdownDuration})<br />
                </span>
            );
        }
    
        // Segundo drawdown (2º maior)
        if (drawdownIndex === 2) {
            const drawdown = data.drawdown_list[1]; // Segundo drawdown
            const drawdownValue = formatBRL(drawdown.drawdown);
            const drawdownDuration = `${drawdown.duration} dias úteis`;
    
            return (
                <span>
                    <strong>2º maior:</strong> {drawdownValue} ({drawdownDuration})<br />
                </span>
            );
        }
    
        // Terceiro drawdown (3º maior)
        if (drawdownIndex === 3) {
            const drawdown = data.drawdown_list[2]; // Terceiro drawdown
            const drawdownValue = formatBRL(drawdown.drawdown);
            const drawdownDuration = `${drawdown.duration} dias úteis`;
    
            return (
                <span>
                    <strong>3º maior:</strong> {drawdownValue} ({drawdownDuration})<br />
                </span>
            );
        }
    
        // Caso o drawdownIndex esteja fora do esperado
        return null;
    };
        
    return (
        <div className="shadow-1 br-1 p-2 mt-2 mb-2">
            {/* Modal Component */}
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={closeModal}>&times;</button>
                        </div>
                        <div
                            ref={scrollRef} // Ref para o div do texto
                            className="modal-body"
                            style={{ overflowY: 'auto', maxHeight: '400px' }}
                            dangerouslySetInnerHTML={{ __html: displayedText }}
                        />
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                            <Button
                                label="Fechar"
                                type="button"
                                className="p-button-raised"
                                onClick={closeModal}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className="prsy-grid prsy-col4 justify-content-between text-center">

                <div className="flex flex-column border-right-1 border-200">
                    <div className="question-container">
                        <b>Payoff Portifólio</b>
                        <span className="question-icon bg-yellow" onClick={() => toggleHelp('payout')}>?</span>
                        {helpVisible === 'payout' && (
                            <div className="help-box">
                                <p><strong>Payoff</strong>: O payoff é a relação entre o ganho médio e a perda média de uma estratégia.
                                <br/><br/>
                                Quando se analisam dois alphas em conjunto, o payoff reflete a <strong>média dos retornos obtidos</strong> quando ambos geram ganhos, comparado às perdas médias.
                                <br/><br/> 
                                Isso ajuda a medir o <strong>potencial de retorno em relação ao risco</strong> envolvido nas operações com ambos os alphas.</p>
                            </div>
                        )}
                    </div>
                    <span>
                        {formatBRL(data.payoff) + " (por dia)"}
                    </span>
                </div>

                <div className="flex flex-column border-right-1 border-200">
                    <div className="question-container">
                        <b>Drawdown Portifólio</b>
                        <span className="question-icon bg-yellow" onClick={() => toggleHelp('drawdown')}>?</span>
                        {helpVisible === 'drawdown' && (
                            <div className="help-box">
                                <strong>Drawdown</strong>: O drawdown representa a maior queda do valor de um portfólio em relação ao seu ponto mais alto anterior.
                                <br/><br/>
                                Em termos simples, ele mede a diferença entre o valor máximo e o valor mínimo que o portfólio atingiu em um determinado período.
                                <br/><br/>
                                No contexto deste portfólio, que combina um *alpha mini índice* e um *alpha mini dólar*, o <strong>drawdown indica o valor necessário de garantia que você precisa manter na corretora</strong> para operar esses dois ativos. Isso serve como uma proteção contra variações adversas de mercado, permitindo que você continue operando sem precisar encerrar posições prematuramente.
                                <br/><br/>
                                Vale ressaltar que o drawdown não representa uma perda de capital permanente, mas sim uma oscilação temporária. Esse valor de garantia pode ser utilizado para manter suas operações ativas dentro do risco definido.
                            </div>
                        )}
                    </div>
                    {/* Alterna entre os três valores de drawdown a cada switchInterval segundos */}
                    {renderDrawdown()}
                </div>

                <div className="flex flex-column border-right-1 border-200">
                    <div className="question-container">
                        <b>Acurácia Portifólio</b>
                        <span className="question-icon bg-yellow" onClick={() => toggleHelp('acuracia')}>?</span>
                        {helpVisible === 'acuracia' && (
                            <div className="help-box">
                                <strong>Acurácia</strong>: A acurácia no contexto de dois alphas refere-se à frequência em que <strong>a soma dos retornos em reais de ambos os alphas é maior que zero</strong> no mesmo dia.
                                <br/>
                                <br/>
                                Isso significa que, se em determinado dia a soma dos resultados de ambos os alphas for positiva, esse dia conta como um acerto para o cálculo da acurácia.
                                <br/>
                                <br/>
                                A acurácia, portanto, mede a <strong>taxa de sucesso conjunta dos alphas</strong>, considerando os dias em que a soma dos retornos de ambos é positiva.
                            </div>
                        )}
                    </div>
                    <span>{(data.acuracia * 100).toFixed(2)}%</span>
                </div>

                <div className="flex flex-column">
                    <div className="question-container">
                        <b>Total Portifólio</b>
                        <span className="question-icon bg-yellow" onClick={() => toggleHelp('acumulo_total')}>?</span>
                        {helpVisible === 'acumulo_total' && (
                            <div className="help-box">
                                <p>
                                <strong>Total</strong>: Refere-se ao <strong>resultado consolidado</strong> do portfólio dos dois alphas, somando os ganhos e perdas ao longo do tempo.
                                <br/><br/> Este valor total reflete o <strong>desempenho acumulado</strong> das operações diárias dos alphas, considerando o quanto o portfólio cresceu (ou diminuiu) em um período específico.
                                </p>
                            </div>
                        )}
                    </div>
                    <span>
                        {formatBRL(data.total_retorno)}
                    </span>
                </div>
            </div>
        </div>
    );
}
