import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";

import BG0 from "../../../assets/principal.jpg";
import BG1 from "../../../assets/bull-bear.jpg";
import BG2 from "../../../assets/automated_trading_vs_manual_trading.jpg";
import BG3 from "../../../assets/shutterstock_361528229.jpg";
import BG4 from "../../../assets/nvidia_verde.jpg";
import { useNavigate } from "react-router-dom";

import InstagramIcon from "../../../assets/instagram-icon.png"; // Ícone do Instagram
import FacebookIcon from "../../../assets/facebook-icon.png"; // Ícone do Facebook
import LinkedInIcon from "../../../assets/linkedin-icon.png"; // Ícone do LinkedIn

export default function HomeBanner() {
    const navigate = useNavigate();

    // Função para embaralhar o array de banners
    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    // Estado para armazenar os banners embaralhados
    const [banners, setBanners] = useState([]);

    // Estado para controlar o banner atual
    const [currentBanner, setCurrentBanner] = useState(0);
    const [isPaused, setIsPaused] = useState(false); // Estado para controlar se está pausado ou não

    // Estado para controlar se o dispositivo é móvel
    const [isMobile, setIsMobile] = useState(false);

    // Verifica o tamanho da tela para definir se é mobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Se a largura for menor ou igual a 768px, consideramos como dispositivo móvel
        };
        
        handleResize(); // Verifica o estado inicial
        window.addEventListener("resize", handleResize); // Atualiza a cada mudança de tamanho da janela

        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const bannersData = [
        {
            title: "Transforme dados em",
            subtitle: "Previsões de Mercado",
            description:
                "Abordagem científica com análise quantitativa para previsões com alta acurácia no mercado de ações.",
            buttonLabel: "Ver planos",
            buttonAction: () => navigate("/planos/"),
            image: BG0,
        },
        {
            title: "Previsões para traders",
            subtitle: "Análise preditiva",
            description:
                "Receba previsões exclusivas para mini índice e mini dólar, otimizando suas operações diárias.",
            buttonLabel: "Sou trader",
            buttonAction: () => navigate("/criar-portifolio-trader/"),
            image: BG1,
        },
        {
            title: "100% automação",
            subtitle: "Sem preocupação",
            description:
                "Deixe nossas ferramentas automatizarem tudo por você. Operações inteligentes com big data e machine learning.",
            buttonLabel: "Quero tudo automatizado",
            buttonAction: () => navigate("/criar-portifolio-automated/"),
            image: BG2,
        },
        {
            title: "NOVO: Portifólio",
            subtitle: "Dois Alphas em Um",
            description:
                "Um portfólio combina mini índice e mini dólar, somando retornos, reduzindo o drawdown e volatilidade dos retornos.",
            buttonLabel: "Portifólio",
            buttonAction: () => navigate("/portifolio-home/"),
            image: BG3,
        },
        {
            title: "NVIDIA Inception",
            subtitle: "Tecnologia de ponta",
            description: "Parceria com NVIDIA Inception. Siga-nos nas redes e acompanhe nossas inovações em IA.",
            socialIcons: true, // Indicador para renderizar ícones sociais neste banner
            image: BG4,
        },
    ];
    
    // Embaralha os banners na primeira renderização
    useEffect(() => {
        const shuffledBanners = shuffleArray([...bannersData]);
        setBanners(shuffledBanners);
    }, []);

    // Função para alternar automaticamente entre os banners
    useEffect(() => {
        if (!isPaused) {
            const interval = setInterval(() => {
                setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
            }, 4000); // Alterna a cada 4 segundos

            return () => clearInterval(interval); // Limpa o intervalo quando o componente desmonta
        }
    }, [banners.length, isPaused]);

    // Função para ir para o banner anterior
    const goToPrevious = () => {
        setCurrentBanner((prevBanner) =>
            prevBanner === 0 ? banners.length - 1 : prevBanner - 1
        );
    };

    // Função para ir para o próximo banner
    const goToNext = () => {
        setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
    };

    // Função para pausar e continuar a rotação dos banners
    const togglePause = () => {
        setIsPaused((prevPaused) => !prevPaused);
    };

    const { title, subtitle, description, buttonLabel, buttonAction, socialIcons, image } =
        banners[currentBanner] || {}; // Adiciona verificação para evitar erro antes dos banners carregarem

    return (
        <div className="grid grid-nogutter surface-800 text-800">
            {/* Botão de Navegação Anterior */}
            <button
                onClick={goToPrevious}
                style={{
                    position: "absolute",
                    top: "30%",
                    left: "0",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    border: "none",
                    padding: "10px",
                    cursor: "pointer",
                    zIndex: 1,
                }}
            >
                &#8249;
            </button>

            <button
                onClick={togglePause}
                style={{
                    position: "absolute",
                    top: "33%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo meio transparente
                    color: "#3b82f6", // Cor do ícone (play/pause)
                    border: "none",
                    padding: "10px 20px",
                    cursor: "pointer",
                    zIndex: 2,
                    borderRadius: "50%",
                    fontSize: "20px",
                    opacity: 0.7,
                }}
            >
                {isPaused ? <i className="fas fa-play"></i> : <i className="fas fa-pause"></i>}
            </button>

            {banners.length > 0 && (
                <>
                    <div className="col-12 md:col-6 p-6 text-left flex align-items-center">
                        <section>
                            <span className="block text-6xl text-0 font-bold mb-1">{title}</span>
                            <div className="text-6xl text-blue-500 font-bold mb-3">{subtitle}</div>
                            <p className="text-2xl mt-0 mb-4 text-0 line-height-3">{description}</p>

                            {socialIcons ? (
                                <div className="social-links flex justify-content-start align-items-center">
                                    <a
                                        href="https://www.instagram.com/alphamonkeyclub/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            borderRadius: "50%",
                                            overflow: "hidden",
                                            marginRight: "10px",
                                        }}
                                    >
                                        <img
                                            src={InstagramIcon}
                                            alt="Instagram"
                                            style={{ width: "50px", height: "50px" }}
                                        />
                                    </a>
                                    <a
                                        href="https://www.facebook.com/profile.php?id=61558475882019"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            borderRadius: "50%",
                                            overflow: "hidden",
                                            marginRight: "10px",
                                        }}
                                    >
                                        <img
                                            src={FacebookIcon}
                                            alt="Facebook"
                                            style={{ width: "50px", height: "50px" }}
                                        />
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/alpha-monkey-club/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            borderRadius: "50%",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <img
                                            src={LinkedInIcon}
                                            alt="LinkedIn"
                                            style={{ width: "50px", height: "50px" }}
                                        />
                                    </a>
                                </div>
                            ) : (
                                <Button
                                    label={buttonLabel}
                                    type="button"
                                    className="mr-3 mb-3 p-button-raised"
                                    onClick={buttonAction}
                                />
                            )}
                        </section>
                    </div>
                    {/* Renderiza a imagem apenas se não for dispositivo móvel */}
                    {!isMobile && (
                        <div className="col-12 md:col-6 overflow-hidden relative">
                            <img
                                src={image}
                                alt="hero-banner"
                                height={"500"}
                                className="md:ml-auto block"
                                style={{
                                    clipPath: "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)",
                                }}
                            />
                        </div>
                    )}
                </>
            )}

            {/* Botão de Navegação Próximo */}
            <button
                onClick={goToNext}
                style={{
                    position: "absolute",
                    top: "32%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    border: "none",
                    padding: "10px",
                    cursor: "pointer",
                    zIndex: 1,
                }}
            >
                &#8250;
            </button>
        </div>
    );
}
