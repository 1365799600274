import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import AlphaChart from "../listAllAlphas/AlphaChart";
import { amc_api } from "../../utils/api_controller"; // Certifique-se de importar corretamente seu controlador de API

export default function AlphaJourneyWin({ data }) {
    const [journeyData, setJourneyData] = useState(data); // Estado inicial com `data`
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [helpVisible, setHelpVisible] = useState(null); // Estado para o help
    const scrollRef = useRef(null);
    const chartHeights = "350px";

    const isPositive = journeyData?.predict_details?.previsao !== "Baixa";
    const isArchived = !!journeyData.metrics.data_fim;

    // Atualiza `journeyData` apenas quando `data` mudar
    useEffect(() => {
        setJourneyData(data);
    }, [data]);

    const formatBRL = (number) =>
        number.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });

    const formatPercentage = (number) =>
        (number / 100).toLocaleString("pt-BR", {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

    const formatTextForDisplay = (text) => {
        if (!text) return "";
        return text
            .replace(/\*(.*?)\*/g, "<strong>$1</strong>") // Negrito
            .replace(/_(.*?)_/g, "<em>$1</em>") // Itálico
            .replace(/~(.*?)~/g, "<u>$1</u>") // Sublinhado
            .replace(/(\r\n|\n|\r)/g, "<br/>"); // Quebra de linha
    };

    const openModal = (content) => {
        setModalContent(formatTextForDisplay(content));
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent("");
    };

    const toggleHelp = async (section) => {
        // Fecha a observação se já estiver aberta
        if (helpVisible === section) {
            setHelpVisible(null);
            return;
        }

        // Marca a observação como lida e exibe o conteúdo da observação
        setHelpVisible(section);
        const isRead = journeyData.metrics[`${section}_observacao_read`];

        if (!isRead) {
            try {
                await amc_api.post(`/alphaportifolio/journey/${journeyData.metrics.codigo_ativo.toLowerCase()}_${journeyData.metrics.tipo_plano.toLowerCase()}/${journeyData.metrics.journey_id}/mark_observation/${section}/`);

                // Atualiza o estado após marcar como lido
                setJourneyData((prevState) => ({
                    ...prevState,
                    metrics: {
                        ...prevState.metrics,
                        [`${section}_observacao_read`]: true,
                    }
                }));
            } catch (error) {
                console.error("Erro ao atualizar a observação:", error);
            }
        }
    };

    // Fecha o modal e as observações ao pressionar "Esc"
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                closeModal();
                setHelpVisible(null); // Fecha também a janela de observação
            }
        };
        if (isModalOpen || helpVisible) {
            document.addEventListener("keydown", handleKeyDown);
        }
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [isModalOpen, helpVisible]);

    return (
        <div className="alphaDetails">
            {/* Modal */}
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header" style={{ paddingBottom: '20px' }}> {/* Espaço maior acima */}
                            <button
                                style={{ marginLeft: 'auto', marginRight: '0', padding: '10px', fontSize: '18px' }} // Botão alinhado à direita
                                onClick={closeModal}
                            >
                                &times;
                            </button>
                        </div>
                        <div
                            ref={scrollRef}
                            className="modal-body"
                            style={{ overflowY: "auto", maxHeight: "400px" }}
                            dangerouslySetInnerHTML={{ __html: modalContent }}
                        />
                        <div className="modal-footer" style={{ textAlign: 'right', paddingTop: '10px' }}>
                            <Button label="Fechar" type="button" onClick={closeModal} />
                        </div>
                    </div>
                </div>
            )}

            {/* Header */}
            <div className="header mt-5 mb-2 flex align-items-center justify-content-between">
                <div className="identification">
                    <div className="text-4xl font-bold">
                        {`${journeyData.metrics.alpha_code}`}
                    </div>
                    <div className="text-2xl">
                        {journeyData.metrics.alpha_description}
                    </div>
                </div>

                {/* Renderiza a seção de previsão apenas se a jornada NÃO estiver arquivada */}
                {!isArchived && (
                    <div className="cta">
                        <div className="flex gap-3">
                            <div className={`flex flex-column text-center p-1 pl-4 pr-4 br-1 ` + (isPositive ? "bg-blue-100" : "bg-orange-100")}>
                                {journeyData.predict_details.predict_details.quant_contratos !== undefined && (
                                    <>
                                        <div className="previsao font-bold">
                                            Previsão para o próximo dia útil
                                        </div>
                                        <div className="previsao font-bold text-2xl">
                                            {journeyData.predict_details.predict_details.previsao}
                                        </div>
                                        <div className="contratos">
                                            {journeyData.predict_details.predict_details.quant_contratos + 
                                                (journeyData.predict_details.predict_details.quant_contratos > 1 ? " contratos" : " contrato")}
                                        </div>
                                    </>
                                )}
                                <div className="contratos">
                                    Atualizado em: {new Date(journeyData.predict_details.predict_details.last_date_mini).toLocaleDateString('pt-BR')}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Métricas */}
            <div className="br-1 mt-3 mb-3">
                <div className="shadow-1 br-1 p-2 mt-2 mb-2">
                    <div className="prsy-grid prsy-col4 justify-content-between text-center">
                        {renderMetric("Acurácia", formatPercentage(journeyData.metrics.acuracia), "acuracia")}
                        {renderMetric(
                            "Drawdown",
                            formatBRL(journeyData.metrics.drawdown_maximo_em_reais),
                            "drawdown"
                        )}
                        {renderMetric(
                            "Payoff",
                            formatBRL(journeyData.metrics.payout_diario_em_reais),
                            "payout"
                        )}
                        {renderMetricTotal(
                            "Total",
                            formatBRL(journeyData.metrics.total_em_reais),
                            "acumulo_total"
                        )}
                    </div>
                </div>
            </div>

            {/* Gráficos */}
            <div className="flex flex-column w-full gap-3 mb-3">
                <div className="chart1">
                    <AlphaChart
                        chartDataLine={journeyData.returns.soma_acumulada_em_reais_mercado}
                        chartDataBar={journeyData.returns.retornos_em_reais_mercado}
                        chartDates={journeyData.returns.datas}
                        initialChartType={"line"}
                        chartLabelLine={"Acumulado em R$"}
                        chartLabelBar={"Retornos em R$"}
                        chartId={journeyData.metrics.alpha_code}
                        chartHeight={chartHeights}
                        timeInterval={0}
                    />
                </div>
                <div className="chart2">
                    <AlphaChart
                        chartDataLine={journeyData.returns.soma_acumulada_em_reais_mercado}
                        chartDataBar={journeyData.returns.retornos_em_reais_mercado}
                        chartDates={journeyData.returns.datas}
                        initialChartType={"bar"}
                        chartLabelLine={"Acumulado em R$"}
                        chartLabelBar={"Retornos em R$"}
                        chartId={journeyData.metrics.alpha_code}
                        chartHeight={chartHeights}
                        timeInterval={0}
                    />
                </div>
            </div>
        </div>
    );

    // Função de renderização das métricas
    function renderMetric(title, value, section) {
        return (
            <div className="flex flex-column border-right-1 border-200">
                <div className="question-container">
                    <b>{title}</b>
                    <span
                        className={`question-icon ${journeyData.metrics[`${section}_observacao_read`] ? "bg-gray" : "bg-yellow"}`}
                        onClick={() => {
                            toggleHelp(section);
                        }}
                    >
                        ?
                    </span>
                    {helpVisible === section && (
                        <div className="help-box">
                            <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(journeyData.metrics[`${section}_observacao`]) }} />
                            <br />
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                openModal(journeyData.metrics[`${section}_detalhe_observacao`]);
                            }}>Saiba mais</a>
                        </div>
                    )}
                </div>
                <span>{value}</span>
            </div>
        );
    }

    // Função de renderização da métrica Total sem borda direita
    function renderMetricTotal(title, value, section) {
        return (
            <div className="flex flex-column">
                <div className="question-container">
                    <b>{title}</b>
                    <span
                        className={`question-icon ${journeyData.metrics[`${section}_observacao_read`] ? "bg-gray" : "bg-yellow"}`}
                        onClick={() => {
                            toggleHelp(section);
                        }}
                    >
                        ?
                    </span>
                    {helpVisible === section && (
                        <div className="help-box">
                            <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(journeyData.metrics[`${section}_observacao`]) }} />
                            <br />
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                openModal(journeyData.metrics[`${section}_detalhe_observacao`]);
                            }}>Saiba mais</a>
                        </div>
                    )}
                </div>
                <span>{value}</span>
            </div>
        );
    }
}
